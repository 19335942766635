import styled from 'styled-components';
import { useEffect } from 'react';

const Veil = styled.div`
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0,0.5);
    position: absolute;
    z-index: 2;
    backdrop-filter: blur(5px);
`;

const VideoContainer = styled.div`
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
    height: 0;
    z-index: 3;
`;

const Iframe = styled.iframe`
    margin: 20px;
    width: 80%;

    @media (max-width: 768px) {
        height: 345px;
    }

    @media (min-width: 768px) {
        height: 690px;
    }
`;

export default function Video() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
        <Veil></Veil>
        <VideoContainer>
            <Iframe 
                src="https://www.youtube.com/embed/-bj1FGBn-VU" 
            />
        </VideoContainer>
        </>
    )
}