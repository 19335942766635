import styled from 'styled-components'
import { useEffect } from 'react';
import { CustomLink } from '../components/customLink';

const MobileHomePageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin: 10px 0 0;
`;

const MobileHomeBackground = styled.div`
    position: fixed;
    z-index: 10;
    width: 100%;
    height: 100vh;
    background-color: #393737;
    `;

const BatLogo = styled.img`
    width: 80%;
    position: relative;
    z-index: 11;
    `;

const Links = styled.div`
    position: relative;
    z-index: 11;
    display: flex;
    flex-direction: column;
    gap: 25px;
    align-items: center;
    font-size: 26px;
    `;

export default function MobileHomePage() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <MobileHomeBackground />
            <MobileHomePageContainer>
                <BatLogo src='/white-bat-logo.svg' />
                <Links>
                    <span>
                        <CustomLink to="/about">
                            About
                        </CustomLink>
                    </span>
                    <span>
                        <CustomLink to="/video">
                            Video
                        </CustomLink>
                    </span>
                    <span>
                        <CustomLink to="/contact">
                            Contact
                        </CustomLink>
                    </span>
                    <span>
                        <CustomLink to="/donate">
                            Donate
                        </CustomLink>
                    </span>
                    <div className="social-footer-icons">
                        <a href="https://www.facebook.com/BatCityBrassBand" target='blank'>
                            <i className="fa-brands fa-facebook" aria-hidden="true" />
                        </a>
                        <a href="https://www.instagram.com/batcitybrassband/?hl=en" target='blank'>
                            <i className="fa-brands fa-instagram" aria-hidden="true" />
                        </a>
                    </div>
                </Links>
            </MobileHomePageContainer>
        </>
    )
}