import { useEffect } from 'react';
import TextContainer from '../components/textContainer';
import Styled from 'styled-components';

const DonateContainer = Styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 230px;

    @media (max-width: 768px) {
        margin-bottom: 70px;
    
        h1 {
            font-size: 1.8rem;
            margin: 0 0 20px;
        }
        
        p {
            font-size: 1rem;
        }
    }
    `;

const Title = Styled.img`
    width: 100%;
    max-width: 455px;
    margin: 0 0 20px;
    `;

const PayPal = Styled.a`
    background-color:#002F85;
    padding: 6px 10px;
    color: white;
    width: 220px;
    display: block;
    margin: 24px auto 0 ;
    border-radius: 10px;
    font-size: 42px;
    `;

const PayPalIcon = Styled.i`
    margin: 0 10px 0 0;
    `;

const Venmo = Styled.a`
    margin: 20px 0 0;
    display: block;
    `;

const DonateLinks = Styled.div`
    @media (max-width: 768px) {
        transform: scale(0.6);
    }
    `;


export default function Donate() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <DonateContainer>
            <TextContainer>
                <Title src="/you-rock.png" alt="Bat City Brass Band" className='bat-logo' />
                <p>
                    Thank you for being a vital part of our musical
                    journey. Together, let's continue to create moments
                    of harmony that resonate with hearts and souls.
                </p>
                <DonateLinks>
                    <PayPal href='https://www.paypal.me/lukewest89' target='_blank'>
                        <PayPalIcon className='fa-brands fa-paypal' />
                        Donate
                    </PayPal>
                    <Venmo href='https://venmo.com/?txn=pay&audience=friends&recipients=@batcitybrassband' target='_blank'>
                        <img src='/venmo.svg' alt='Venmo' />
                    </Venmo>
                </DonateLinks>
            </TextContainer>
        </DonateContainer>
    )
}    
