import './App.scss';
import About from './pages/about';
import Contact from './pages/contact';
import { Routes, Route } from 'react-router-dom';
import Footer from './components/footer';
import Video from './pages/video';
import Donate from './pages/donate';
import MobileHomePage from './pages/mobileHomePage';

function App() {
  const isMobile = window.innerWidth < 768;
  return (
    <div className="App">
        <Routes >
          <Route path="/" element={isMobile ? <MobileHomePage/> : <></>}/>
          <Route path="/about" element={<About isMobile={isMobile}/>} />
          <Route path="/contact" element={<Contact/>}/>
          <Route path="/video" element={<Video/>}/>
          <Route path="/donate" element={<Donate/>}/>
        </Routes>
      <Footer isMobile={isMobile}/>
    </div>
  );
}

export default App;
