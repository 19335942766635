import styled from 'styled-components';

const ImageGridContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin: 40px 0 0;
`;

const Rows = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
`;

const Img1 = styled.img`
    width: 40%;
`;

const MobileImg = styled.img`
    width: 100%;
`;

export default function ImageGrid(props) {
    const { isMobile } = props;

    return (
        <ImageGridContainer>
            <Rows>
                {!isMobile &&
                <>
                    <Row>
                        <Img1 src='https://res.cloudinary.com/dhrvrcn5g/image/upload/v1690398235/ltm77k0zybevfn8gytdk.jpg' />
                        <Img1 src='https://res.cloudinary.com/dhrvrcn5g/image/upload/v1690398635/bat-city-1_y2q9tf.jpg' />
                    </Row>

                    <Row>
                        <Img1 src='https://res.cloudinary.com/dhrvrcn5g/image/upload/v1690398239/t7ej0a3pjluotm29cfpo.jpg' />
                        <Img1 src='https://res.cloudinary.com/dhrvrcn5g/image/upload/v1690398240/s7vq6ablrlm4au02yrxz.jpg' />
                    </Row>
                    </>
                }
                {isMobile &&
                <>
                    <MobileImg src='https://res.cloudinary.com/dhrvrcn5g/image/upload/v1690398635/bat-city-1_y2q9tf.jpg' />
                    <MobileImg src='https://res.cloudinary.com/dhrvrcn5g/image/upload/v1690398235/ltm77k0zybevfn8gytdk.jpg' />
                    <MobileImg src='https://res.cloudinary.com/dhrvrcn5g/image/upload/v1690398240/s7vq6ablrlm4au02yrxz.jpg' />
                    <MobileImg src='https://res.cloudinary.com/dhrvrcn5g/image/upload/v1690398239/t7ej0a3pjluotm29cfpo.jpg' />
                    </>
                }
            </Rows>
        </ImageGridContainer>

    )
}