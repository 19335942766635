import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useMatch, useResolvedPath } from 'react-router';
import MobileMenu from './mobileMenu';

const FooterContainer = styled.div`
    position: fixed;
    z-index: 3;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 20px 0 30px;
    background: #B72A20;
`;

function CustomLink({ to, children, ...props }) {
    const resolvedPath = useResolvedPath(to)
    const isActive = useMatch({ path: resolvedPath.pathname, end: true })

    return (
        <Link to={to} {...props} className={isActive ? "active" : ""}>
            {children}
        </Link>
    )
}

const BatLogo = styled.img`
    width: 350px;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 4;

    @media (max-width: 768px) {
        width: 100px;
    }
`;

export default function Footer(props) {
    const { isMobile } = props;
    return (
        <>
            <BatLogo src="/white-bat-logo.svg" alt="Bat City Brass Band" className='bat-logo' />
            <FooterContainer>
                {!isMobile &&
                    <>
                        <div className="navbar">
                            <span>
                                <CustomLink to="/">
                                    Home
                                </CustomLink>
                            </span>
                            <span>
                                <CustomLink to="/about">
                                    About
                                </CustomLink>
                            </span>
                            <span>
                                <CustomLink to="/video">
                                    Video
                                </CustomLink>
                            </span>
                            <span>
                                <CustomLink to="/contact">
                                    Contact
                                </CustomLink>
                            </span>
                            <span>
                                <CustomLink to="/donate">
                                    Donate
                                </CustomLink>
                            </span>
                        </div>
                        <div className="social-footer-icons">
                            <a href="https://www.facebook.com/BatCityBrassBand" target='blank'>
                                <i className="fa-brands fa-facebook" aria-hidden="true" />
                            </a>
                            <a href="https://www.instagram.com/batcitybrassband/?hl=en" target='blank'>
                                <i className="fa-brands fa-instagram" aria-hidden="true" />
                            </a>
                        </div>
                    </>
                }
                {isMobile &&
                    <MobileMenu />
                }
            </FooterContainer>
        </>
    )
}
