import styled from 'styled-components';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useMatch, useResolvedPath } from 'react-router';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const FooterContainer = styled.div`
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #B72A20;
    padding: 6px;
    font-size: 25px;
`;

const ExpandedMenu = styled.div`
    gap: 6px;
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #B72A20;
    transition: bottom 0.5s ease-in-out;
    font-size: 1.5rem;

    &.hide {
        bottom: -240px;
    }
`;

function CustomLink({ to, children, ...props }) {
    const resolvedPath = useResolvedPath(to)
    const isActive = useMatch({ path: resolvedPath.pathname, end: true })

    return (
        <Link to={to} {...props} className={isActive ? "active" : ""}>
            {children}
        </Link>
    )
}

const XButton = styled.div`
    padding: 15px;
    font-size: 25px;
`;

export default function MobileMenu() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const navigate = useNavigate();
    useEffect(() => {
        // Attach the event listener when the component mounts
        const unlisten = (() => setIsMenuOpen(false));
    
        // Clean up the event listener when the component unmounts
        return () => {
          unlisten();
        };
      }, [navigate])

    function getMobileVisibilityClass() {
        return isMenuOpen ? 'show' : 'hide';
    }

    const handleScroll = () => {
        setIsMenuOpen(false);
      };
    
      useEffect(() => {
        // Attach the scroll event listener when the component mounts
        window.addEventListener('scroll', (handleScroll));
    
        // Clean up the event listener when the component unmounts
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []); 

    return (
        <>
                <FooterContainer>
                    <div onClick={() => setIsMenuOpen(true)}>
                        <i className="fa-solid fa-bars" aria-hidden="true" />
                    </div>
                </FooterContainer>
            
                <ExpandedMenu className={getMobileVisibilityClass()}>
                    <span>
                        <CustomLink to="/about">
                            About
                        </CustomLink>
                    </span>
                    <span>
                        <CustomLink to="/video">
                            Video
                        </CustomLink>
                    </span>
                    <span>
                        <CustomLink to="/contact">
                            Contact
                        </CustomLink>
                    </span>
                    <span>
                        <CustomLink to="/donate">
                            Donate
                        </CustomLink>
                    </span>
                    <div className="social-footer-icons">
                        <a href="https://www.facebook.com/BatCityBrassBand" target='blank'>
                            <i className="fa-brands fa-facebook" aria-hidden="true" />
                        </a>
                        <a href="https://www.instagram.com/batcitybrassband/?hl=en" target='blank'>
                            <i className="fa-brands fa-instagram" aria-hidden="true" />
                        </a>
                    </div>
                    <XButton onClick={() => setIsMenuOpen(false)}>
                        <i className="fa-solid fa-times" aria-hidden="true" />
                    </XButton>
                </ExpandedMenu>
        </>
    )
}