import TextContainer from "../components/textContainer";
import styled from 'styled-components';
import { useEffect } from 'react';

const EmailLink = styled.a`
    font-size: 1.5rem;
    font-family: 'Roboto', sans-serif;
    color: white;
    
    @media (max-width: 768px) {    
        font-size: 1rem;
    };
`;

const ContactTextContainer = styled(TextContainer)`
    max-width: 700px;
    margin: 220px auto;

    @media (max-width: 768px) {
        margin-top: 100px;
    }
    `;

export default function Contact() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
            <ContactTextContainer>
                For booking inquiries, please contact us at: <br/>
                <EmailLink href="mailto:batcitybrassband@gmail.com">
                    batcitybrassband@gmail.com
                </EmailLink>
            </ContactTextContainer>
    )
}    