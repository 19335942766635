import styled from 'styled-components';

const TextContainer = styled.div`
    margin: 20px 40px;
    max-width: 900px;
    padding: 20px;
    background: rgba(38, 38, 38, 0.76);;
    box-shadow: 1px 1px 4px 0px black;
    backdrop-filter: blur(5px);

    h1 {
        font-size: 3rem;
        text-align: center;
        margin-bottom: 40px;
    }

    p {
        text-align: left;
        margin: 0 20px;
    }
`;

export default TextContainer;