import { useEffect } from 'react';
import TextContainer from '../components/textContainer';
import Styled from 'styled-components';
import ImageGrid from '../components/imageGrid';

const StyledAbout = Styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 230px;

    @media (max-width: 768px) {
        margin-bottom: 70px;
    
        h1 {
            font-size: 1.8rem;
            margin: 0 0 20px;
        }
        
        p {
            font-size: 1rem;
        }
    }
    
`;

const AboutTitle = Styled.img`
    width: 100%;
    max-width: 455px;
    margin: 0 0 20px;
`;



export default function About(props) {
    const { isMobile } = props;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <StyledAbout>
            <TextContainer>
                <AboutTitle src="/light-hearts.svg" alt="Bat City Brass Band" className='bat-logo' />
                <p>
                Bat City Brass Band is an Austin-based group 
                that plays high-energy dance music with an 
                international sound. Our influences come from New 
                Orleans, New York, Brazil, Egypt, and the Balkans. 
                Get ready for trumpet, trombone, drums, saxes, and 
                ground-shaking tuba!
                </p>
                <ImageGrid isMobile={isMobile}/>
            </TextContainer>
        </StyledAbout>
    )
}    